export default {
    commandAliases: {
        id: 'commandSettings.CommandAliases'
    },
    commandAliasesError: {
        id: 'commandSettings.CommandAliasesValidationError'
    },
    commandEnabled: {
        id: 'commandSettings.CommandEnabled'
    },
    adminOnly: {
        id: 'commandSettings.AdminOnly'
    },
    description: {
        id: 'commandSettings.Description'
    },
    descriptionError: {
        id: 'commandSettings.DescriptionError',
    },
    save: {
        id: 'common.Save',
    }
}
