
export default {
    debtorsOnly: 'Только должники',
    seller: 'Продавец',
    idOrName: 'ID или Имя',
    openVkProfile: 'Посмотреть VK профиль',
    openVkChat: 'Открыть VK чат',
    copyVkId: 'Скопировать VK ID',
    changeLoyaltyStatus: 'Изменить статус лояльности',
    "common.Yes": 'Да',
    "common.No": 'Нет',
    "common.Success": 'Успех',
    "common.Save": 'Сохранить',
    "common.Rating": 'Рейтинг',
    "common.Loyalty": 'Лояльность',
    "common.Sellers": 'Продавцы',
    "common.Settings": 'Настройки',
    "mainMenu.MessageText": 'Текст сообщений',
    "mainMenu.CommandSettings": 'Настройка команд',
    "loyalty.Balance": `Баланс: {balance}`,
    "loyalty.DueDate": `Активен до: {date}`,
    "loyalty.EnableMessage": 'Включить абонемент',
    "loyalty.DisableConfirmation": 'Вы уверены что хотите выключить абонемент пользователю',
    "loyalty.DisableConfirmationTitle": 'Вы уверены что хотите отключить абонемент?',
    "commandSettings.CommandAliases": 'Алиасы команды',
    "commandSettings.CommandAliasesValidationError": 'Поле алиасы команд обязательно',
    "commandSettings.CommandEnabled": 'Команда включена',
    "commandSettings.AdminOnly": 'Только для админов',
    "commandSettings.Description": 'Описание команды в хелпе',
    "commandSettings.DescriptionError": 'Поле описание обязательно',
    "groupSettings.digestSettings.Title": 'Настройки дайджеста',
    "groupSettings.digestSettings.AutoPosting": 'Авто пост',
    "groupSettings.digestSettings.Attach": 'Закрепить',
    "groupSettings.digestSettings.Other": 'Другое',
    "groupSettings.DeliveryDisabled": 'Поле доставка отключено',
    "groupSettings.FreeAuctions": 'Бесплатные аукционы',
    "groupSettings.DailyPostLimit": 'Дневной лимит постов',
    "groupSettings.CustomCondition": 'Произвольное задание состояния лота',
    "groupSettings.ModerationType": 'Режим модерации',
    "groupSettings.UpdateAucPostWithStatus": 'Обновление статуса аукциона',
    "groupSettings.commissionSettings.Title": 'Настройки комиссии',
    "groupSettings.commissionSettings.Commission": 'Комиссия',
    "groupSettings.commissionSettings.MinCommission": 'Минимальная комиссия',
    "groupSettings.commissionSettings.CommissionType": 'Тип комиссии',
    "groupSettings.commissionSettings.CommissionAbove1000": 'Комиссия более 1000',
    "groupSettings.commissionSettings.CommissionBelow1000": 'Комиссия менее 1000',
    "groupSettings.commissionSettings.CommissionFromStartPrice": 'Комиссия от старт цены',
    "seller.Title": 'Продавец',
    "seller.AucsCount": 'Количество аукционов: {count}',
    "seller.Rating": 'Рейтинг: {rating}',
    "seller.NotPayedAuctions": 'Неоплаченные аукционы',
    "auctions.AucNumber": `Номер аукциона: {number}`,
    "auctions.ThreeDaysNotified": 'Уведомления о трех днях:',
    "auctions.WeekNotified": 'О неделе',
    'auctions.EndDate': 'Дата завершения: {date}',
    "auctions.WinningBid": `Победившая ставка: {bid} руб.`,
    "auctions.WallLink": 'Ссылка на стену',
    "stringsSettings.aucCreation.Title": 'Настройки создания аукциона',
    "stringsSettings.aucCreation.Rules": 'Правила',
    "stringsSettings.aucCreation.MessageDescription": 'Сообщение ввода описания',
    "stringsSettings.aucCreation.MessageCity": 'Сообщение ввода города',
    "stringsSettings.aucCreation.MessageCondition": 'Сообщение ввода состояния',
    "stringsSettings.aucCreation.MessagePayment": 'Сообщение ввода способов оплаты',
    "stringsSettings.aucCreation.Hashtags": 'Хэштэги',
    "stringsSettings.aucCreation.Novice": 'Предупреждение о новичке',
    "stringsSettings.debtors.Title": 'Настройки сообщения должникам',
    "stringsSettings.debtors.MainMessage": 'Основное сообщение',
    "stringsSettings.debtors.MessageThreeDays": 'Сообщение о просрочке в три дня',
    "stringsSettings.debtors.MessageWeek": 'Сообщение о просрочке в неделю',
    "stringsSettings.debtors.MessageError": 'Сообщение о возможно ошибке',
    "stringsSettings.digest.Title": 'Настройки дайджеста',
    "stringsSettings.digest.Delimiter": 'Разделитель в дайджесте'

} as const
