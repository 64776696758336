import {useMutation} from "@tanstack/react-query";
import axios, {AxiosResponse} from "axios";

import {getCompleteAPIUrl} from "./utils";
import {API_ROUTES} from "constants/routes";
import {StringsSettingsRequest, StringsSettingsResponse} from "types/api/strings";

export const useGetCurrentStrings = () => {
    return useMutation<AxiosResponse<StringsSettingsResponse>, void, number>(['strings'], (groupid) => (axios(getCompleteAPIUrl(API_ROUTES.STRINGS), {params: {groupid}})))
}

export const usePostCurrentStrings = () => {
    return useMutation<void, void, StringsSettingsRequest>(['stringsPost'], (data) => axios.post(getCompleteAPIUrl(API_ROUTES.STRINGS), {...data}))
}
