import StringSettingsForm from "components/StringSettingsForm";


const StringSettings = () => {
    return (
        <StringSettingsForm/>
    )
}

export default StringSettings;
