import { useEffect, useRef } from 'react';

function useDebouncedCallback<A extends any[], R>(
    callback: (...args: A) => R,
    wait: number
) {
    // track args & timeout handle between calls
    const argsRef = useRef<A>();
    const timeout = useRef<ReturnType<typeof setTimeout>>();

    function cleanup() {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
    }

    // make sure our timeout gets cleared if
    // our consuming component gets unmounted
    useEffect(() => cleanup, []);

    return function debouncedCallback(...args: A) {
        // capture latest args
        argsRef.current = args;

        // clear debounce timer
        cleanup();

        return new Promise<R>((resolve) => {
            timeout.current = setTimeout(() => {
                if (argsRef.current) {
                    resolve(callback(...argsRef.current));
                }
            }, wait);
        });
        // start waiting again
    };
}

export default useDebouncedCallback;
