export default {
    success: {
        id: 'common.Success',
    },
    save: {
        id: 'common.Save',
    },
    digestSettings: {
        id: 'groupSettings.digestSettings.Title',
    },
    autoPost: {
        id: 'groupSettings.digestSettings.AutoPosting'
    },
    attachPost: {
        id: 'groupSettings.digestSettings.Attach'
    },
    other: {
        id: 'groupSettings.digestSettings.Other',
    },
    deliveryDisabled: {
        id: 'groupSettings.DeliveryDisabled'
    },
    freeAuctions: {
        id: 'groupSettings.FreeAuctions'
    },
    dailyPostLimit: {
        id: 'groupSettings.DailyPostLimit'
    },
    customCondition: {
        id: 'groupSettings.CustomCondition'
    },
    moderationType: {
        id: 'groupSettings.ModerationType'
    },
    updatePostWithStatus: {
        id: 'groupSettings.UpdateAucPostWithStatus'
    },
    commissionSettings: {
        id: 'groupSettings.commissionSettings.Title'
    },
    commission: {
        id: 'groupSettings.commissionSettings.Commission',
    },
    minCommission: {
        id: 'groupSettings.commissionSettings.MinCommission'
    },
    commissionType: {
        id: 'groupSettings.commissionSettings.CommissionType',
    },
    commissionAbove1000: {
        id: 'groupSettings.commissionSettings.CommissionAbove1000'
    },
    commissionBelow1000: {
        id: 'groupSettings.commissionSettings.CommissionBelow1000'
    },
    commissionFromStartPrice: {
        id: 'groupSettings.commissionSettings.CommissionFromStartPrice'
    }
}
