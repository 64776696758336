export default {
    seller: {
        id: 'seller',
    },
    debtors: {
        id: 'debtorsOnly'
    },
    idOrNamePlaceholder: {
        id: 'idOrName',
    },
    openVkProfile: {
        id: 'openVkProfile'
    },
    openVkChat: {
        id: 'openVkChat'
    },
    copyVkId: {
        id: 'copyVkId',
    },
    changeLoyaltyStatus: {
        id: 'changeLoyaltyStatus'
    },
    rating: {
        id: 'common.Rating'
    },
    loyalty: {
        id: 'common.Loyalty'
    },
    loyaltyBalance: {
        id: 'loyalty.Balance'
    },
    loyaltyDate: {
        id: 'loyalty.DueDate',
    }
}
