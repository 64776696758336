import React, {createContext, ReactNode, useContext, useMemo, useReducer} from "react";

export const ACTION_TYPE = {
    OPEN: 'open',
    CLOSE: 'close',
} as const;

type Modal<T = any> = {
    open: boolean;
    data: T
}

type Modals = {
    [k: string]: Modal
}

type Action<T = unknown> = {
    type: typeof ACTION_TYPE[keyof typeof ACTION_TYPE],
    name: string;
    data?: T,
}

const ModalContext = createContext<{modals: Modals, dispatch: React.Dispatch<Action>}>({test: 1} as any);

const modalsReducer = (modals: Modals , action: Action): Modals => {
    switch (action.type) {
        case ACTION_TYPE.OPEN: {
            const newModals = {...modals};
            newModals[action.name] = {...newModals[action.name], open: true, data: action.data}
            return newModals;
        }
        case ACTION_TYPE.CLOSE:
            // eslint-disable-next-line
            const newModals = {...modals};
            newModals[action.name] = {...newModals[action.name], open: false, data: undefined}
            return newModals;
        default:
            return modals;
    }
}
export const ModalProvider = ({children}: {children: ReactNode}) => {
    const [modals, dispatch] = useReducer(modalsReducer, {});
    const contextObject = useMemo(()=> ({modals, dispatch}), [modals, dispatch])
    return (
        <ModalContext.Provider value={contextObject}>
            {children}
        </ModalContext.Provider>
    );
}

export const useModals = <T, >(modalName: string) => {
    const modalsContext = useContext(ModalContext);
    return {modal: modalsContext.modals[modalName] as Modal<T>, dispatch: modalsContext.dispatch};
}

export const useModalsDispatch = ()=> {
    return useContext(ModalContext).dispatch;
}
