import {useParams} from "react-router-dom";
import {useEffect, useMemo} from "react";
import {useIntl} from "react-intl";

import {useLazyGetSeller} from "../../api/sellers";
import {Avatar, Spin} from "antd";
import NotPayedAuction from "./NotPayedAuction/NotPayedAuction";

import messages from "./messages";
import styles from './Seller.module.scss';

const Seller = () => {
    const intl = useIntl();
    const {groupId, sellerId} = useParams();
    const selectedGroupId = useMemo(() => Number(groupId), [groupId]);
    const selectedSellerId = useMemo(() => Number(sellerId), [sellerId]);

    const {mutateAsync: getSeller, data, isLoading} = useLazyGetSeller();

    const sellerInfo = data?.data;

    const fullName = `${sellerInfo?.profile?.first_name} ${sellerInfo?.profile?.last_name}`;

    const notPayedAuctions = sellerInfo?.notPayedAuctions.reverse().slice(0, 10);

    useEffect(() => {
        getSeller({groupId: selectedGroupId, sellerId: selectedSellerId})
    }, [selectedGroupId, getSeller, selectedSellerId])


    return (<div className={styles.seller}>
        <Spin spinning={isLoading}>
            {!isLoading && <div>
                <div className={styles.sellerHeader}>
                    <Avatar size="large" shape="square" src={sellerInfo?.profile?.photo_100}/>
                    <h2><a href={`https://vk.com/id${sellerInfo?.profile?.id}`} target="_blank"> {fullName}</a></h2>
                </div>
                <div className={styles.content}>
                    <div>{intl.formatMessage(messages.aucsCount, {count:sellerInfo?.totalAuctionCount})}</div>
                    <div>{intl.formatMessage(messages.sellerRating, {rating: sellerInfo?.rating})}</div>
                    <h2>{intl.formatMessage(messages.sellerNotPayedAuctions)}</h2>
                    {notPayedAuctions?.map((auction) => <NotPayedAuction key={auction.aucId} auction={auction}/>)}
                </div>
            </div>
            }
        </Spin>
    </div>);
}

export default Seller;
