import {Form, Spin, Collapse, Input, Button, message} from "antd";
import React, {useEffect, useMemo} from "react";
import {useParams} from "react-router-dom";

import {useGetCurrentStrings, usePostCurrentStrings} from "api/stringsSettings";

import styles from './StringsSettingsForm.module.scss';
import messages from "./messages";
import {useIntl} from "react-intl";

const {Panel} = Collapse;
const {TextArea} = Input;

const StringSettingsForm = () => {
    const intl = useIntl();
    const {groupId} = useParams();
    const selectedGroupId = useMemo(() => Number(groupId), [groupId]);
    const {mutateAsync: getStringsSettings, data, isLoading: isGetLoading, isSuccess} = useGetCurrentStrings();
    const {mutateAsync: postStringsSettings, isLoading: isPostLoading} = usePostCurrentStrings();
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();

    useEffect(() => {
        getStringsSettings(selectedGroupId);
    }, [selectedGroupId, getStringsSettings]);

    useEffect(() => {
        if (isSuccess) {
            form.resetFields();
        }
    }, [isSuccess, form]);

    const renderHeader = (text: string) => (<div className={styles.panelHeader}>{text}</div>)
    const shouldShowSpin = isGetLoading || isPostLoading;

    const handleSubmit = async (formData: any) => {
        await postStringsSettings({...formData, groupId: selectedGroupId})
        messageApi.success('Строки успешно обновлены');
    }

    return (
        <Spin spinning={shouldShowSpin} size="large">
            {contextHolder}
            <div className={styles.formWrapper}>
                <Form form={form} onFinish={handleSubmit} initialValues={data?.data} layout="vertical">
                    <Collapse ghost>
                        <Panel header={renderHeader(intl.formatMessage(messages.aucCreation))} key="1">
                            <Form.Item
                                name={['createAuction', 'rules']}
                                label={intl.formatMessage(messages.rules)}>
                                <TextArea rows={5}/>
                            </Form.Item>
                            <Form.Item
                                name={['createAuction', 'enter_description']}
                                label={intl.formatMessage(messages.description)}>
                                <TextArea rows={5}/>
                            </Form.Item>
                            <Form.Item
                                name={['createAuction', 'enter_city']}
                                label={intl.formatMessage(messages.city)}>
                                <TextArea rows={5}/>
                            </Form.Item>
                            <Form.Item
                                name={['createAuction', 'enter_condition']}
                                label={intl.formatMessage(messages.condition)}>
                                <TextArea rows={5}/>
                            </Form.Item>
                            <Form.Item
                                name={['createAuction', 'enter_payment_options']}
                                label={intl.formatMessage(messages.payment)}>
                                <TextArea rows={5}/>
                            </Form.Item>
                            <Form.Item
                                name={['createAuction', 'hashtags']}
                                label={intl.formatMessage(messages.hashtags)}>
                                <TextArea rows={5}/>
                            </Form.Item>
                            <Form.Item
                                name={['createAuction', 'warningNovice']}
                                label={intl.formatMessage(messages.novice)}>
                                <TextArea rows={5}/>
                            </Form.Item>
                        </Panel>
                        <Panel header={renderHeader(intl.formatMessage(messages.debtorsTitle))} key="2">
                            <Form.Item
                                name={['debtors', 'mainMessage']}
                                label={intl.formatMessage(messages.debtorsMessageBody)}>
                                <TextArea rows={5}/>
                            </Form.Item>
                            <Form.Item
                                name={['debtors', 'warningThreeDays']}
                                label={intl.formatMessage(messages.debtorsThreeDays)}>
                                <TextArea rows={5}/>
                            </Form.Item>
                            <Form.Item
                                name={['debtors', 'warningWeek']}
                                label={intl.formatMessage(messages.debtorsWeek)}>
                                <TextArea rows={5}/>
                            </Form.Item>
                            <Form.Item
                                name={['debtors', 'mistake']}
                                label={intl.formatMessage(messages.debtorsMessageError)}>
                                <TextArea rows={5}/>
                            </Form.Item>
                        </Panel>
                        <Panel header={renderHeader(intl.formatMessage(messages.digestTitle))} key="3">
                            <Form.Item
                                name={['digest', 'digest_delimiter']}
                                label={intl.formatMessage(messages.digestDelim)}>
                                <TextArea rows={5}/>
                            </Form.Item>
                        </Panel>
                    </Collapse>
                    <Form.Item label="">
                        <Button className={styles.submitButton} type="primary" htmlType="submit">
                            {intl.formatMessage(messages.save)}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Spin>
    )
}

export default StringSettingsForm;
