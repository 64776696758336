export default {
    enableLoyalty: {
        id: 'loyalty.EnableMessage'
    },
    disableLoyaltyTitle: {
        id: 'loyalty.DisableConfirmationTitle'
    },
    disableLoyalty: {
        id: 'loyalty.DisableConfirmation',
    },
    balance: {
        id: 'loyalty.Balance'
    },
    loyalDate: {
        id: 'loyalty.DueDate'
    }
}
