import axios, {AxiosResponse} from "axios";
import {useMutation, useQuery} from "@tanstack/react-query";

import {API_ROUTES} from "constants/routes";
import {getCompleteAPIUrl} from "./utils";
import {GroupAPIResponse, GroupSettings, GroupSettingsPostRequest} from "types/api/groups";


export const useLazyGetGroupsList = () => {
    return useQuery<AxiosResponse<GroupAPIResponse>>(['getGroupList'], () => axios(getCompleteAPIUrl(API_ROUTES.GROUPS_LIST)), {enabled: false});
}

export const useGetGroupSettings = () => {
    return useMutation<AxiosResponse<GroupSettings>, void, number>( (groupid) => axios.get(getCompleteAPIUrl(API_ROUTES.GROUP_SETTINGS,), {params: {groupid}}))
}

export const usePostGroupSettings = () => {
    return useMutation<AxiosResponse, void, GroupSettingsPostRequest>((data) => axios.post(getCompleteAPIUrl(API_ROUTES.GROUP_SETTINGS), {...data}))
}
