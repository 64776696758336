import {Avatar, Card, Modal} from "antd";
import {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import LoyalForm from "./LoyalForm";
import {useModals} from "contexts/ModalContexts";
import {LOYAL_MODAL, LOYAL_TYPE} from "./constants";
import {Seller} from "types/api/sellers";
import {useLazyDeleteSellerLoyal, useLazyPostSellerLoyal} from "api/sellers";

import messages from "./messages";
import {useIntl} from "react-intl";

type LoyalData = {
    seller: Seller
}

type FormData = {
    type: typeof LOYAL_TYPE[keyof typeof LOYAL_TYPE],
    loyalBalance?: number,
    loyalDate?: number,
}


const LoyalModal = () => {
    const intl = useIntl();
    const {modal, dispatch} = useModals<LoyalData>(LOYAL_MODAL);
    const [isOpen, setIsOpen] = useState(false);
    const [formData, setFormData] = useState<FormData>();
    const { groupId} = useParams();

    const {mutateAsync: deleteLoyal} = useLazyDeleteSellerLoyal();
    const {mutateAsync: postLoyal} = useLazyPostSellerLoyal();

    const handleCancel = useCallback(() => {
            dispatch({type: "close", name: LOYAL_MODAL});
            setFormData(undefined);
        }, [dispatch])


    useEffect(() => {
        if (modal) {
            setIsOpen(modal?.open)
        }
    }, [modal]);

    const seller = modal?.data?.seller || {};

    const {first_name, last_name, photo_100, loyal, id} = seller;
    const fullName = `${first_name} ${last_name}`;

    const handleOk = useCallback(() => {
        if (loyal?.isLoyal) {
            deleteLoyal({groupId: Number(groupId), sellerId: id})
        } else {
            postLoyal({sellerId: id, groupId: Number(groupId), balance: formData?.loyalBalance, date: formData?.loyalDate});
        }
        handleCancel();
    }, [id, loyal, deleteLoyal, postLoyal, formData, groupId, handleCancel])

    if(!modal?.open){
        return null;
    }

    if (loyal?.isLoyal) {
        return (
            <Modal
                maskClosable
                title={intl.formatMessage(messages.disableLoyaltyTitle)}
                open={isOpen}
                onCancel={handleCancel}
                onOk={handleOk}
            >
                <>
                    <Card>
                        <Card.Meta
                            title={fullName}
                            avatar={<Avatar src={photo_100}/>}
                            description={<div>
                                {loyal.balance ? intl.formatMessage(messages.balance, {balance:loyal.balance }) : intl.formatMessage(messages.loyalDate, {date: new Date((loyal?.dueDate || 0) * 1000).toDateString()})}
                            </div>}
                        />
                    </Card>
                    <div>
                        {intl.formatMessage(messages.disableLoyalty)} {fullName} ?
                    </div>
                </>
            </Modal>
        );
    }

    return (
        <Modal
            maskClosable
            title={intl.formatMessage(messages.enableLoyalty)}
            open={isOpen}
            onCancel={handleCancel}
            onOk={handleOk}
        >
            <>
                <Card>
                    <Card.Meta
                        title={fullName}
                        avatar={<Avatar src={photo_100}/>}
                    />
                </Card>
                <div>
                    <h2>Абонемент</h2>
                    <LoyalForm setFormData={setFormData}/>
                </div>
            </>
        </Modal>
    )
}
export default LoyalModal;
