import React from "react";

import GroupSettingsForm from "components/GroupSettingsForm";

const Settings = () => {
    return (
        <GroupSettingsForm/>
    )
}

export default Settings;
