import {Card, Avatar, Tooltip} from "antd";
import {Link} from "react-router-dom";
import {MessageOutlined, UserOutlined, CopyOutlined, BankOutlined} from "@ant-design/icons";
import {useCallback, useMemo} from "react";

import {Seller} from "types/api/sellers";
import {useModalsDispatch} from "contexts/ModalContexts";
import {LOYAL_MODAL} from "components/Modals/Loyal/constants";


import styles from './SellerCard.module.scss'
import {useIntl} from "react-intl";
import messages from "../messages";


type Props = {
    seller: Seller
}
const SellerCard = ({seller}: Props) => {
    const intl = useIntl();
    const dispatch = useModalsDispatch();

    const {first_name, last_name, photo_100, auctionsCount, rating, notPayedAuctionsCount, id, loyal} = seller;
    const fullName = `${first_name} ${last_name}`;

    const openLoyalModel = useCallback(() => {
        dispatch({type: "open", name: LOYAL_MODAL, data: {seller}})
    }, [
        dispatch, seller
    ])

    const actions = useMemo(() => {
        const visitVkProfileButton = <Tooltip
            title={intl.formatMessage(messages.openVkProfile)}>
            <a
                href={`https://vk.com/id${id}`}
                target="_blank"><UserOutlined/></a></Tooltip>;

        const sendMessageVk = <Tooltip
            title={intl.formatMessage(messages.openVkChat)}> <a
            href={`https://vk.com/im?sel=${id}`}
            target="_blank"><MessageOutlined/></a></Tooltip>;

        const copyVkID = <Tooltip title={intl.formatMessage(messages.copyVkId)}> <CopyOutlined
            onClick={() => {
                navigator.clipboard.writeText(String(id))
            }}/></Tooltip>;

        const setLoyal = <Tooltip title={intl.formatMessage(messages.changeLoyaltyStatus)}> <BankOutlined
            onClick={openLoyalModel}/> </Tooltip>;

        return [visitVkProfileButton, sendMessageVk, copyVkID, setLoyal]
    }, [id, openLoyalModel, intl])

    const renderCardDescription = useCallback(() => {
        return (
            <div>Количество аукционов: {auctionsCount}. Неоплаченных аукционов: <span
                className={notPayedAuctionsCount! > 0 ? styles.warning : ''}>{notPayedAuctionsCount}</span>.
                {intl.formatMessage(messages.rating)}: {rating}.
                {loyal?.isLoyal &&
                    <span> {intl.formatMessage(messages.loyalty)}: {loyal.balance ? intl.formatMessage(messages.loyaltyBalance, {balance: loyal.balance}) : intl.formatMessage(messages.loyaltyDate, {date: new Date((loyal?.dueDate || 0) * 1000).toDateString()})} </span>}
            </div>
        );
    }, [auctionsCount, notPayedAuctionsCount, loyal, rating, intl])


    return (
        <Card actions={actions}>
            <Link to={String(seller.id)}>
                <Card.Meta
                    title={fullName}
                    avatar={<Avatar src={photo_100}/>}
                    description={renderCardDescription()}
                />
            </Link>
        </Card>
    )
}

export default SellerCard;
