import CommandSettingsForm from "components/CommandSettingsForm";


const CommandSettings = () => {
    return (
        <CommandSettingsForm/>
    )
}

export default CommandSettings;
