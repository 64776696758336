import React, {useMemo} from "react";
import {LinkProps, NavLink} from 'react-router-dom';
import {Avatar, List, Divider} from "antd";

import {FileTextOutlined, SettingFilled, MacCommandOutlined, SmileOutlined} from '@ant-design/icons';
import ROUTES from "constants/routes";

import Profile from "../Profile";
import GroupSelector from "../GroupSelector";

import styles from './MainMenu.module.scss';
import messages from "./messages";
import {useIntl} from "react-intl";


type MenuItem = {
    title: string;
    link: LinkProps['to'];
    icon: React.ReactNode;
}

const MainMenu = () => {
    const intl = useIntl();
    const dataSource: Array<MenuItem> = useMemo(() => ([
        {
            title: intl.formatMessage(messages.settings),
            icon: <SettingFilled/>,
            link: ROUTES.SETTINGS
        },
        {
            title:  intl.formatMessage(messages.messagesText),
            link: ROUTES.TEXT_SETTINGS,
            icon: <FileTextOutlined/>
        },
        {
            title: intl.formatMessage(messages.commandSettings),
            link: ROUTES.COMMANDS_SETTINGS,
            icon: <MacCommandOutlined/>
        },
        {
            title: intl.formatMessage(messages.sellers),
            link: ROUTES.SELLERS,
            icon: <SmileOutlined/>
        }
    ]), [intl]);

    return (
        <div className={styles.menuWrapper}>
            <List
                dataSource={dataSource}
                renderItem={(item: MenuItem) => (
                    <NavLink className={({isActive}) => (isActive ? styles.activeLink : '')} to={item.link}>
                        <List.Item className={styles.listItemWrapper}>
                            <div className={styles.listItem}>
                                <List.Item.Meta
                                    avatar={<Avatar icon={item.icon}/>}
                                />
                                <span>{item.title}</span>
                            </div>
                        </List.Item>
                    </NavLink>
                )}/>
            <div>
                <GroupSelector/>
                <Divider/>
                <Profile/>
            </div>
        </div>
    )
}

export default MainMenu;
