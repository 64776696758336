import axios from "axios";
import ROUTES, {API_PATH} from "constants/routes";

const isProd = import.meta.env.PROD;

export const setupAxiosInterceptors = () => {
    axios.defaults.withCredentials = true;
    axios.interceptors.response.use((response) => {
        if (response.status === 401) {
            const oldPathname = window.location.pathname;
            window.location.replace(`${ROUTES.LOGIN}?redirect=${oldPathname}`);
        }
        return response;
    }, (error) => {
        if (error?.response?.status === 401) {
            const oldPathname = window.location.pathname;
            window.location.replace(`${ROUTES.LOGIN}?redirect=${oldPathname}`);
        }
        return Promise.reject(error);
    })
}

export const getCompleteAPIUrl = (url: string) => {
    return `${!isProd ? `http://localhost:5001${API_PATH}` : `https://vkaucsbot.ru${API_PATH}`}${url}`;
}

export const getLoginUrl = (url: string) => {
    return `${!isProd ? `http://localhost:5001` : 'https://vkaucsbot.ru'}${url}`;
}

