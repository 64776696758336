export default {
    sellers: {
        id: 'common.Sellers'
    },
    settings: {
        id: 'common.Settings'
    },
    messagesText: {
        id: 'mainMenu.MessageText'
    },
    commandSettings: {
        id: 'mainMenu.CommandSettings'
    }
}
