import React from 'react'
import ReactDOM from 'react-dom/client'
import {
    RouterProvider,
} from "react-router-dom";
import {ConfigProvider, theme} from 'antd';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'

import App from "./App";
import {setupAxiosInterceptors} from "./api/utils";
import router from "./routing/routing";
import {ModalProvider} from "./contexts/ModalContexts";
import {IntlProvider} from "react-intl";

import './index.scss';
import messagesRu from './intl/ru';


const queryClient = new QueryClient();

setupAxiosInterceptors();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <App>
            <ModalProvider>
                <IntlProvider messages={messagesRu} locale="ru" defaultLocale="ru">
                <QueryClientProvider client={queryClient}>
                    <ConfigProvider theme={{algorithm: theme.darkAlgorithm}}>
                        <RouterProvider router={router}/>
                    </ConfigProvider>
                </QueryClientProvider>
                </IntlProvider>
            </ModalProvider>
        </App>
    </React.StrictMode>,
)
