export default {
    seller: {
        id: 'seller.Title'
    },
    aucsCount: {
        id: 'seller.AucsCount'
    },
    sellerRating: {
        id: 'seller.Rating',
    },
    sellerNotPayedAuctions: {
        id: 'seller.NotPayedAuctions'
    },
    aucNumber: {
        id: 'auctions.AucNumber'
    },
    notifiedThree: {
        id: 'auctions.ThreeDaysNotified'
    },
    notifiedWeek: {
        id:'auctions.WeekNotified'
    },
    yes: {
        id: 'common.Yes',
    },
    no: {
        id: 'common.No',
    },
    endDate: {
        id: 'auctions.EndDate'
    },
    winningBid: {
        id: 'auctions.WinningBid'
    },
    wallLink: {
        id: 'auctions.WallLink',
    }
}
