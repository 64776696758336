import React, {useEffect} from "react";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import MainMenu from "components/MainMenu";
import {useHookstate} from '@hookstate/core';

import GLOBAL_STATE from "constants/state";
import ROUTES from "constants/routes";
import {useLazyGetGroupsList} from "api/groups";
import {useLazyGetUser} from "api/auth";

import styles from './MainPage.module.scss';
import ModalManager from "../../components/Modals/ModalManager";


const MainPage = () => {
    const globalState = useHookstate(GLOBAL_STATE);
    const {data: groupData, refetch: fetchGroups, isSuccess} = useLazyGetGroupsList();
    const {data: userData, refetch: fetchUser} = useLazyGetUser();
    const {groupId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
            fetchGroups();
            fetchUser();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    useEffect(() => {
        if (groupData) {
            globalState.groups.set(groupData.data);
            if (!groupId) {
                navigate(`/${groupData.data[0].id}`);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupData])

    useEffect(() => {
        if (userData) {
            globalState.user.set(userData?.data)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData])

    useEffect(() => {
        const selectedGroup = groupData?.data.find(group => group.id === Number(groupId));
        if (groupId && isSuccess && !selectedGroup) {
            navigate(ROUTES.LOGIN);
        }
    }, [groupId, groupData, navigate, isSuccess])

    return (
        <div className={styles.gridWrapper}>
            <div className={styles.menu}>
                <MainMenu/>
            </div>
            <div className={styles.content}>
                <Outlet/>
                <ModalManager/>
            </div>
        </div>
    )
}

export default MainPage;
