import {useQuery} from "@tanstack/react-query";
import axios, {AxiosResponse} from "axios";

import {getCompleteAPIUrl} from "./utils";
import {API_ROUTES} from "constants/routes";
import {User} from "types/api/user";

export const useLazyGetUser = () => {
    return useQuery<AxiosResponse<User>>(['getUser'], () => axios(getCompleteAPIUrl(API_ROUTES.USER)), {enabled: false});
}
