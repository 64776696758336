import {Form, InputNumber, Radio, Select} from "antd";

import {LOYAL_TYPE} from "./constants";

type Props = {
    setFormData: (data: any)=>unknown
}

const DATE_PICKER_VALUES = [{value:7, label: '7 дней'}, {value:30, label: '30 дней'}, {value:100, label: '100 дней'}]

const LoyalForm = ({setFormData}: Props) => {
    const [form] = Form.useForm();
    const type = Form.useWatch('type', form);

    return (
        <Form
            onValuesChange={(_, values)=> {
                setFormData(values);
            }}
            form={form}>
            <Form.Item name="type" label="Вид абонемента">
                <Radio.Group>
                    <Radio value={LOYAL_TYPE.DATE}>До даты</Radio>
                    <Radio value={LOYAL_TYPE.BALANCE}>Баланс</Radio>
                </Radio.Group>
            </Form.Item>
            {type === LOYAL_TYPE.DATE && <Form.Item name="loyalDate" label="Время действия">
                <Select
                    options={DATE_PICKER_VALUES}
                />
            </Form.Item>}
            {type === LOYAL_TYPE.BALANCE && <Form.Item name="loyalBalance" label="Баланс">
                <InputNumber min={1} max={100000}/>
            </Form.Item>}
        </Form>
    )
}


export default LoyalForm;
