import {useMutation} from "@tanstack/react-query";
import axios, {AxiosResponse} from "axios";

import {getCompleteAPIUrl} from "./utils";
import {API_ROUTES} from "../constants/routes";
import {CommandsSettingsRequest, CommandsSettingsResponse} from "../types/api/commands";

export const useGetCommandSettings = () => {
    return useMutation<AxiosResponse<CommandsSettingsResponse>, void, number>(['commands'], (groupid) => (axios(getCompleteAPIUrl(API_ROUTES.COMMANDS), {params: {groupid}})))
}

export const usePostCommandSettings = () => {
    return useMutation<void, void, CommandsSettingsRequest>(['commandsPost'], (data) => axios.post(getCompleteAPIUrl(`${API_ROUTES.COMMANDS}/${data.id}`), {...data.data}))
}
