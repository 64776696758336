import {Form, Input, Switch} from "antd";
import {useCallback} from "react";
import {useIntl} from "react-intl";

import useDebounce from "utils/useDebounce";

import styles from './Filters.module.scss';
import messages from "../messages";


type Props = {
    changeFilters: (filters: { [k: string]: string }) => void;
}

const Filter = ({changeFilters}: Props) => {
    const intl = useIntl();
    const [form] = Form.useForm();
    const handleValuesChange = useCallback(() => {
        changeFilters(form.getFieldsValue())
    }, [changeFilters, form])

    const debouncedHandleChangeValues = useDebounce(handleValuesChange, 500)

    return (
        <div className={styles.filters}>
            <Form onValuesChange={debouncedHandleChangeValues} form={form} layout="inline">
                <Form.Item name="filter" label={intl.formatMessage(messages.seller)}>
                    <Input placeholder={intl.formatMessage(messages.idOrNamePlaceholder)}/>
                </Form.Item>
                <Form.Item name="debtorsOnly" label={intl.formatMessage(messages.debtors)}>
                    <Switch/>
                </Form.Item>
            </Form>
        </div>
    )
}

export default Filter
