import {useParams} from "react-router-dom";
import {useIntl} from "react-intl";

import {Auction} from "types/api/auctions";

import styles from './NotPayedAuctions.module.scss'
import messages from "../messages";


type Props = {
    auction: Auction;
}

const NotPayedAuction = ({auction}: Props) => {
    const intl = useIntl()
    const {groupId} = useParams();

    return (
        <div className={styles.auctions}>
            <div>
                {intl.formatMessage(messages.aucNumber, {number: auction.aucNumber})}
            </div>
            <div>
                {intl.formatMessage(messages.notifiedThree)} {auction.notified ? intl.formatMessage(messages.yes) : intl.formatMessage(messages.no)}. {intl.formatMessage(messages.notifiedWeek)} {auction.notifiedWeek ? intl.formatMessage(messages.yes) : intl.formatMessage(messages.no)}
            </div>
            <div>
                {intl.formatMessage(messages.endDate, {date: new Date(auction!.endDate * 1000).toLocaleDateString()})}
            </div>
            <div>
                {intl.formatMessage(messages.winningBid, {bid: auction.winningBid})}
            </div>
            <div>
                <a href={`https://vk.com/wall-${groupId}_${auction.postId}`} target="_blank">
                    {intl.formatMessage(messages.wallLink)}
                </a>
            </div>
        </div>
    )
}

export default NotPayedAuction;
