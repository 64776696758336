import {useParams} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import {Pagination, Spin} from "antd";
import {useIsMutating} from "@tanstack/react-query";

import {useLazyGetSellerList} from "api/sellers";
import SellerCard from "./SellerCard/SellerCard";
import {SellersListResponse} from "types/api/sellers";
import Filters from "./Filters/Filters";

import styles from './SellerList.module.scss';


const SellersList = () => {
    const {groupId} = useParams();
    const selectedGroupId = useMemo(() => Number(groupId), [groupId]);
    const [filters, setFilters] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const {mutateAsync: getSellersList, data, isLoading} = useLazyGetSellerList();

    const sellersData: SellersListResponse = data?.data || {} as SellersListResponse;

    const {data: sellersList = [], total} = sellersData;

    const isMutatingLoyalDelete = useIsMutating( {mutationKey: ['deleteLoyal']});
    const isMutatingLoyalPost = useIsMutating( {mutationKey: ['postLoyal']});


    useEffect(() => {
        getSellersList({groupId: selectedGroupId, ...filters, page: currentPage});
    }, [groupId, filters, getSellersList, selectedGroupId, currentPage])

    useEffect(() => {
        setCurrentPage(1);
    }, [groupId, filters])

    useEffect(() => {
        // reload seller list if we updated the loyalty for user
        if(!isMutatingLoyalDelete || !isMutatingLoyalPost){
            getSellersList({groupId: selectedGroupId, ...filters, page: currentPage});
        }
    }, [isMutatingLoyalDelete, isMutatingLoyalPost, groupId, filters, getSellersList, selectedGroupId, currentPage]);

    return (
        <Spin spinning={isLoading} size="large">
            <Filters changeFilters={setFilters}/>
            {!isLoading && <>
                <Pagination
                    responsive
                    current={currentPage}
                    pageSize={sellersList?.length}
                    showSizeChanger={false}
                    onChange={(page) => setCurrentPage(page)}
                    total={total}/>
                <div className={styles.list}>
                    {
                        sellersList.map(seller => <SellerCard key={seller.id} seller={seller}/>)
                    }
                </div>
            </>}
        </Spin>
    )
}

export default SellersList;
