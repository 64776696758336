import {Col, Form, InputNumber, Row, Select, Switch} from "antd";
import React from "react";
import {COMMISSION_TYPES} from "constants/groups";
import {useIntl} from "react-intl";
import messages from "../messages";


const commissionTypeOptions = [{
    value: COMMISSION_TYPES.STARTING,
    label: 'От стартовой цены'
}, {value: COMMISSION_TYPES.ENDING, label: 'От конечной цены'}]

const CommissionSelector = () => {
    const intl = useIntl();

    const form = Form.useFormInstance();
    const commissionValue = Form.useWatch(['commission', 'enabled'], form);
    const commissionType = Form.useWatch(['commission', 'type'], form);
    const isCommissionFromStartingPrice = commissionType === COMMISSION_TYPES.STARTING;


    return <>
        <h1>{intl.formatMessage(messages.commissionSettings)}</h1>
        <Row>
            <Col span={12}>
                <Form.Item valuePropName="checked" name={['commission', 'enabled']} label={intl.formatMessage(messages.commission)}>
                    <Switch/>
                </Form.Item>
                <Form.Item name={['commission', 'minCommission']} label={intl.formatMessage(messages.minCommission)}>
                    <InputNumber disabled={!commissionValue} prefix="₽"/>
                </Form.Item>

            </Col>
            <Col span={12}>
                <Form.Item name={['commission', 'type']} label={intl.formatMessage(messages.commissionType)}>
                    <Select options={commissionTypeOptions} disabled={!commissionValue}/>
                </Form.Item>
                {!isCommissionFromStartingPrice && <>
                    <Form.Item name={['commission', 'aboveThousand']} label={intl.formatMessage(messages.commissionAbove1000)}>
                        <InputNumber disabled={!commissionValue || isCommissionFromStartingPrice} prefix="%"/>
                    </Form.Item>
                    <Form.Item name={['commission', 'underThousand']} label={intl.formatMessage(messages.commissionBelow1000)}>
                        <InputNumber disabled={!commissionValue || isCommissionFromStartingPrice} prefix="%"/>
                    </Form.Item>
                </>}
                {isCommissionFromStartingPrice &&
                    <Form.Item name={['commission', 'percentFromStartingPrice']} label={intl.formatMessage(messages.commissionFromStartPrice)}>
                        <InputNumber disabled={!commissionValue} prefix="%"/>
                    </Form.Item>
                }
            </Col>
        </Row>
    </>
}

export default CommissionSelector;
