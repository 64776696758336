export const MODERATION_OPTIONS = {
    NO_MODERATION : 'no-moderation',
    MODERATE_WITH_TIME: 'moderate-with-time',
    MODERATE_ALL: 'moderate-all',
} as const


export const COMMISSION_TYPES = {
    ENDING: 'ENDING',
    STARTING: 'STARTING',
} as const;
