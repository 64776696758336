import {Avatar} from "antd";
import {useHookstate} from "@hookstate/core";
import GLOBAL_STATE from "constants/state";

import styles from './Profile.module.scss';

const Profile = () => {
    const globalState = useHookstate(GLOBAL_STATE);
    const user = globalState.user.get();
    return (
        <div className={styles.profileWrapper}>
            <Avatar src={user.photoUrl}/> <span className={styles.profileText}>{user.displayName}</span>
        </div>
    );
}

export default Profile;
