import React, {useCallback, useEffect, useMemo} from "react";
import {Button, Form, Switch, Input, Row, Col, Spin, Select} from "antd";
import {useParams} from "react-router-dom";
import {useIntl} from "react-intl";

import {useGetCommandSettings, usePostCommandSettings} from "api/commands";

import styles from './CommandSettings.module.scss';
import messages from "./messages";

const {TextArea} = Input;


const CommandSettingsForm = () => {
    const intl = useIntl();
    const {groupId} = useParams();
    const selectedGroupId = useMemo(() => Number(groupId), [groupId]);
    const [form] = Form.useForm();
    const {mutateAsync: getCommandSettings, data, isLoading: isGetLoading, isSuccess} = useGetCommandSettings();
    const {mutateAsync: postCommandSettings, isLoading} = usePostCommandSettings();

    useEffect(() => {
        getCommandSettings(selectedGroupId);
    }, [getCommandSettings, selectedGroupId]);

    useEffect(() => {
        if (isSuccess) {
            form.resetFields();
        }
    }, [isSuccess, form]);

    const handleSubmit = useCallback((values: any) => {
        postCommandSettings({id: selectedGroupId, data: values})
    }, [selectedGroupId, postCommandSettings]);

    const renderForm = useMemo(() => {
        const renderData = data?.data || {};
        const commandKeys = Object.keys(renderData);
        return (<>
                {commandKeys.map((key) => {
                    const setting = renderData[key];
                    return (<div key={key}>
                        <h1>{setting.name}</h1>
                        <Form.Item
                            valuePropName="defaultValue" name={[key, 'alias']} label={intl.formatMessage(messages.commandAliases)}
                            rules={[{required: true, message: intl.formatMessage(messages.commandAliasesError)}]}>
                            <Select mode="tags" tokenSeparators={[',']}/>
                        </Form.Item>
                        <Row>
                            <Col span={12}>
                                <Form.Item label={intl.formatMessage(messages.commandEnabled)} valuePropName="checked" name={[key, 'enabled']}>
                                    <Switch/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={intl.formatMessage(messages.adminOnly)} valuePropName="checked"
                                    name={[key, 'adminOnly']}>
                                    <Switch/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            name={[key, 'description']} label={intl.formatMessage(messages.description)}
                            rules={[{required: true, message: intl.formatMessage(messages.descriptionError)}]}>
                            <TextArea rows={5}/>
                        </Form.Item>
                    </div>)
                })}
            </>
        );
    }, [data?.data, intl]);

    const shouldShowSpinner = isGetLoading || isLoading;

    return (
        <div className={styles.formWrapper}>
            <Spin spinning={shouldShowSpinner} size="large">
                <Form
                    form={form} onFinish={handleSubmit} initialValues={data?.data}
                    layout="vertical"
                    scrollToFirstError>
                    {renderForm}
                    <Form.Item label="">
                        <Button type="primary" htmlType="submit">
                            {intl.formatMessage(messages.save)}
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}

export default CommandSettingsForm;
