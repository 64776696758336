import {useMemo} from "react";
import {useHookstate} from "@hookstate/core";
import {useMatches, useParams, useNavigate} from "react-router-dom";
import {Avatar, Dropdown, MenuProps} from "antd";

import GLOBAL_STATE from "constants/state";

import styles from './GroupSelector.module.scss';

const GroupSelector = () => {
    const globalState = useHookstate(GLOBAL_STATE);
    const { groupId } = useParams();
    const matches = useMatches();
    const groups = globalState.groups.get();
    const navigate = useNavigate();

    const selectedGroup = useMemo(() => (groups.find(group => group.id === Number(groupId))), [groupId, groups]);
    const menuItems = useMemo(() => {
        const menuItems: MenuProps['items'] = [];
        groups.forEach(group => {
            if (group.id !== Number(groupId)) {
                menuItems.push({
                    label: group.name, icon: <Avatar src={group.photo_100}/>, key: group.id, onClick: () => {
                        const navigateUrl = matches.at(-1)!.pathname.replace(groupId!, String(group.id));
                        navigate(navigateUrl);
                    }
                })
            }
        });
        return menuItems;
    }, [groups, groupId, matches, navigate]);
    return (
        <Dropdown placement="top" trigger={['click']} menu={{items: menuItems}} arrow>
            <div className={styles.groupWrapper}>
                    <Avatar className={styles.avatarWrapper} shape="square" src={selectedGroup?.photo_50}/> <span
                    className={styles.groupName}>{selectedGroup?.name}</span>
            </div>
        </Dropdown>
    )
}

export default GroupSelector;
