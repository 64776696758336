import React from "react";
import {Button} from "antd";
import {useSearchParams} from "react-router-dom";

import {getLoginUrl} from "api/utils";

import styles from './Login.module.scss';

const Login = () => {

    const [searchParams] = useSearchParams();

    const loginUrl = getLoginUrl(`/auth/vkontakte?redirect=${searchParams.get("redirect")}`)

    return (
        <div className={styles.loginPage}>
            <Button size="large" type="default" href={loginUrl}>
                Войти
            </Button>
        </div>
    )
}

export default Login
