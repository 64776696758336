type Props = {
    children: JSX.Element
}

const App = ({children}: Props) => {

    return (<div>{children}</div>)
}

export default App;
