const ROUTES = {
    MAIN_PAGE: '/:groupId',
    SETTINGS: 'settings',
    TEXT_SETTINGS: 'text-settings',
    COMMANDS_SETTINGS: 'commands-settings',
    SELLERS: 'sellers',
    SELLER: 'sellers/:sellerId',


    LOGIN:"/login",
}
export const API_PATH = '/api';

export const API_ROUTES = {
    GROUPS_LIST: `/getGroupList`,
    USER: `/user`,
    GROUP_SETTINGS: '/settings',
    AUTH_VK: '/auth/vkontakte',
    STRINGS: '/strings',
    COMMANDS: '/commands',
    SELLERS: '/sellers',
    SELLER: '/seller',
    LOYAL: '/seller/:userId/loyal'
};


export default ROUTES;
