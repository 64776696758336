import ru from "../../intl/ru";

const messages = {
    aucCreation: {
        id: 'stringsSettings.aucCreation.Title'
    },
    rules: {
        id: 'stringsSettings.aucCreation.Rules'
    },
    description: {
        id: 'stringsSettings.aucCreation.MessageDescription',
    },
    city: {
        id: 'stringsSettings.aucCreation.MessageCity',
    },
    condition: {
        id: 'stringsSettings.aucCreation.MessageCondition',
    },
    payment: {
        id: 'stringsSettings.aucCreation.MessagePayment',
    },
    hashtags: {
        id: 'stringsSettings.aucCreation.Hashtags',
    },
    novice: {
        id: 'stringsSettings.aucCreation.Novice',
    },
    debtorsTitle: {
        id: 'stringsSettings.debtors.Title'
    },
    debtorsMessageBody: {
        id: 'stringsSettings.debtors.MainMessage',
    },
    debtorsThreeDays: {
        id: 'stringsSettings.debtors.MessageThreeDays',
    },
    debtorsWeek: {
        id: 'stringsSettings.debtors.MessageWeek',
    },
    debtorsMessageError: {
        id: 'stringsSettings.debtors.MessageError',
    },
    digestTitle: {
        id: 'stringsSettings.digest.Title'
    },
    digestDelim: {
        id: 'stringsSettings.digest.Delimiter'
    },
    save: {
        id: 'common.Save'
    }
} as const as Record<string, { id: keyof typeof ru }>

export default messages;
