import {createBrowserRouter} from "react-router-dom";
import React from "react";

import MainPage from "pages/MainPage";
import ROUTES from "constants/routes";
import Settings from "pages/Settings";
import StringSettings from "pages/StringSettings";
import CommandSettings from "pages/CommandSettings";
import Sellers from "pages/Sellers/Sellers";
import Login from "pages/Login/login";
import Seller from "pages/Seller/Seller";

const router = createBrowserRouter([
    {
        path: ROUTES.MAIN_PAGE,
        element: <MainPage/>,
        children: [
            {
                path: ROUTES.SETTINGS, element: <Settings/>,
            },
            {
                path: ROUTES.TEXT_SETTINGS, element: <StringSettings/>,
            },
            {
                path: ROUTES.COMMANDS_SETTINGS, element: <CommandSettings/>
            },
            {
                path: ROUTES.SELLERS, element: <Sellers/>
            },
            {
                path: ROUTES.SELLER, element: <Seller/>
            }
        ],
    },
    {
        path: "/login",
        element: <Login/>
    },
    {
        path: "/",
        element: <MainPage/>,
    }
]);

export default router;
