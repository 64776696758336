import {hookstate} from "@hookstate/core";
import {GlobalState} from "types/globalState";
import {GroupAPI} from "../types/api/groups";

const INITIAL_STATE = {
    user: {},
    groups: [] as Array<GroupAPI>,
} as GlobalState

const GLOBAL_STATE = hookstate<GlobalState>(INITIAL_STATE);

export default GLOBAL_STATE;
