import {useMutation} from "@tanstack/react-query";
import axios, {AxiosResponse} from "axios";
import {getCompleteAPIUrl} from "./utils";
import {API_ROUTES} from "../constants/routes";
import {generatePath} from "react-router-dom";
import {
    LoyalSellerPostRequest,
    SellerListRequest,
    SellerRequest,
    SellerResponse,
    SellersListResponse
} from "types/api/sellers";


export const useLazyGetSellerList = () => {
    return useMutation<AxiosResponse<SellersListResponse>, void, SellerListRequest>(['getSellerList'], ({groupId, filter, debtorsOnly, page}) => axios(getCompleteAPIUrl(API_ROUTES.SELLERS), {params: {groupId, filter,debtorsOnly, page }}));
}


export const useLazyGetSeller = () => {
    return useMutation<AxiosResponse<SellerResponse>, void, SellerRequest>(['getSeller'], ({groupId, sellerId}) => axios(getCompleteAPIUrl(API_ROUTES.SELLER), {params: {groupId, sellerId }}));
}

export const useLazyDeleteSellerLoyal = () => {
    return useMutation<AxiosResponse, void, SellerRequest>(['deleteLoyal'], ({
                                                                               groupId,
                                                                               sellerId
                                                                           }) => axios.delete(generatePath(getCompleteAPIUrl(API_ROUTES.LOYAL), {userId: sellerId}), {params: {groupId}}));
}

export const useLazyPostSellerLoyal = () => {
    return useMutation<AxiosResponse, void, LoyalSellerPostRequest>(['postLoyal'], ({groupId, sellerId, balance, date,}) => axios.post(generatePath(getCompleteAPIUrl(API_ROUTES.LOYAL), {userId: sellerId}) , {groupId, balance, date}));
}
