import {Form, Switch, InputNumber, Select, Button, Col, Row, Spin, message} from "antd";
import React, {useCallback, useEffect, useMemo} from "react";

import {MODERATION_OPTIONS} from "constants/groups";
import {useGetGroupSettings, usePostGroupSettings} from "api/groups";

import styles from './GroupSettings.module.scss';
import CommissionSelector from "./CommissionSelector/CommissionSelector";
import {useParams} from "react-router-dom";
import {useIntl} from "react-intl";
import messages from "./messages";

const moderationOptions = [{
    value: MODERATION_OPTIONS.NO_MODERATION,
    label: 'Нет модерации'
}, {
    value: MODERATION_OPTIONS.MODERATE_WITH_TIME,
    label: 'Модерация со временем'
}, {value: MODERATION_OPTIONS.MODERATE_ALL, label: 'Полная модерация'}]

const GroupSettingsForm = () => {
    const intl = useIntl();
    const {groupId} = useParams();
    const selectedGroupId = useMemo(() => Number(groupId), [groupId]);
    const {data, isLoading, isSuccess, mutate: getGroupSettings} = useGetGroupSettings();
    const {mutateAsync: postGroupSettings, isLoading: isPostSettingsLoading} = usePostGroupSettings();
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();

    useEffect(() => {
        getGroupSettings(selectedGroupId);
    }, [selectedGroupId, getGroupSettings]);

    useEffect(() => {
        if (isSuccess) {
            form.resetFields();
        }
    }, [isSuccess, form]);


    const handleFormSubmit = useCallback(async (formData: any) => {
        await postGroupSettings({...formData, groupId: selectedGroupId});
        messageApi.success(intl.formatMessage(messages.success))
    }, [postGroupSettings, selectedGroupId, messageApi, intl])

    const shouldShowSpinner = isPostSettingsLoading || isLoading;
    return (
        <div className={styles.formWrapper}>
            {contextHolder}
            <Spin spinning={shouldShowSpinner} size="large">
                <Form
                    form={form}
                    layout="vertical"
                    colon={false}
                    initialValues={data?.data}
                    onFinish={handleFormSubmit}
                >
                    <h1>{intl.formatMessage(messages.digestSettings)}</h1>
                    <Row>
                        <Col span={12}>
                            <Form.Item valuePropName="checked" name="auto_digest" label={intl.formatMessage(messages.autoPost)}>
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item valuePropName="checked" name="pin_digest" label={intl.formatMessage(messages.attachPost)}>
                                <Switch/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <CommissionSelector/>
                    <h1>{intl.formatMessage(messages.other)}</h1>
                    <Row>
                        <Col span={12}>
                            <Form.Item valuePropName="checked" name="isDeliveryDisabled" label={intl.formatMessage(messages.deliveryDisabled)}>
                                <Switch/>
                            </Form.Item>
                            <Form.Item valuePropName="checked" name="isFreeAuctionsEnabled" label={intl.formatMessage(messages.freeAuctions)}>
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="post_limit" label={intl.formatMessage(messages.dailyPostLimit)}>
                                <InputNumber></InputNumber>
                            </Form.Item>
                            <Form.Item
                                valuePropName="checked"
                                name="custom_condition"
                                label={intl.formatMessage(messages.customCondition)}>
                                <Switch/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item name="moderation_status" label={intl.formatMessage(messages.moderationType)}>
                        <Select options={moderationOptions}></Select>
                    </Form.Item>
                    <Form.Item
                        valuePropName="checked"
                        name="updateAuctionPostWithState"
                        label={intl.formatMessage(messages.updatePostWithStatus)}>
                        <Switch/>
                    </Form.Item>
                    <Form.Item label="">
                        <Button type="primary" htmlType="submit">
                            {intl.formatMessage(messages.save)}
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    );
}

export default GroupSettingsForm;
